import { Injectable, NgZone } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController, ToastController, ActionSheetController, LoadingController, AlertController, PopoverController } from '@ionic/angular';
import { Platform } from '@ionic/angular';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { HttpService } from './http.service';
import { GlobalService } from './global.service';
import { PickerController } from '@ionic/angular';
import domtoimage from 'dom-to-image';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import {Md5} from 'ts-md5/dist/md5';
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';
// import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import * as moment from 'moment';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireAnalytics } from "@angular/fire/analytics";
import firebase from "firebase/app"
import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';
import { Device } from '@ionic-native/device/ngx';
// import { OneSignal, OSNotification, OSNotificationPayload } from '@ionic-native/onesignal/ngx';
import { SignInWithApple, AppleSignInResponse, AppleSignInErrorResponse, ASAuthorizationAppleIDRequest } from '@ionic-native/sign-in-with-apple/ngx';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import OneSignal, { PushSubscriptionChangedState } from 'onesignal-cordova-plugin';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class PageService {
  loading: any;
  moduleName = '';
  // qrElementType = NgxQrcodeElementTypes.URL;
  // qrCorrectionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  hideMenu: Boolean = false;
  devicePlatform: any;
  // messages: OSNotificationPayload[] = [];
  private publicKey = 'TEST-e7135cf8-75eb-4c70-a64a-af190f1b31ef'; // Reemplaza con tu Public Key
  private accessToken = 'TEST-7398579033405690-072408-09308e9c0367c25b967b7dfcd7a16e01-93313525'; // Reemplaza con tu Access Token


  constructor(
    public activatedRoute: ActivatedRoute,
    public modalCtrl: ModalController,
    public geolocation: Geolocation,
    public camera: Camera,
    public loadingController: LoadingController,
    public actionSheetController: ActionSheetController,
    public platform: Platform,
    public global: GlobalService,
    public httpService: HttpService,
    public location: Location,
    public router: Router,
    public toastCtrl: ToastController,
    public alertCtrl: AlertController,
    public popoverController: PopoverController,
    public zone: NgZone,
    public pickerCtrl: PickerController,
    public socialSharing: SocialSharing,
    public iab: InAppBrowser,
    public callNumber: CallNumber,
    public diagnostic: Diagnostic,
    public fireAuth: AngularFireAuth,
    public fireAnalytics: AngularFireAnalytics,
    public google: GooglePlus,
    public firebaseAnalytics: FirebaseAnalytics,
    public device: Device,
    private http: HttpClient,
    // public oneSignal: OneSignal,
    public apple: SignInWithApple
  ) {
    this.platform.ready().then( () => {
      this.devicePlatform = this.device?.platform?.toLowerCase();
      console.log('PLATFORM IS: ' + this.devicePlatform);
    });
  }


  // (+) Navigation

  navigate(endPoint = '') {
    //this.router.navigate(['/' + this.getModuleName() + endPoint]);
    this.navigateRoute('/' + this.getModuleName() + endPoint);
  }

  navigateRoute(route,extra = {}) {
    this.router.navigate([route],extra);
  }

  navigateReplace(url, params = {}) {
    this.router.navigate([url, params], {skipLocationChange: true, replaceUrl: false});
  }

 

  navigateBack() {
    this.location.back();
  }

  backToHome() {
    window.history.go((window.history.length - 1) * -1);
  }

  // (-) Navigation

  // (+) Module name

  getModuleName() {
    return this.location.path().split('/')[1];
  }

  // (-) Module name

  // (+) Http

  getHttpEndPoint() {
    return '/' + this.getModuleName();
  }

  httpGetAll( endPoint, showLoading = true, sort:any = { name: 1 } , filters:any = {}, populates:any = [], page:any = -1) {
    return this.httpService.getAll( endPoint, filters, showLoading, sort, populates, page );
  }

  httpRemove( endPoint, item ) {
    return this.httpService.remove( endPoint, item );
  }

  httpCreate( endPoint, item ) {
    console.log(item, "ITEM")
    return this.httpService.create( endPoint, item );
  }

  httpUpdate( endPoint, item  ) {
    return this.httpService.update( endPoint, item );
  }

  httpGetById( endPoint, id, showLoading = true, populates:any = [] ) {
    return this.httpService.getById( endPoint, id, showLoading, populates );
  }

  httpPut( endPoint, values ) {
    return this.httpService.put( endPoint, values );
  }

  httpPost( endPoint, values, showLoading = true, oldServerUrl = false ) {
    return this.httpService.post( endPoint, values, showLoading, oldServerUrl );
  }

  httpDelete( endPoint ) {
    return this.httpService.delete( endPoint );
  }

  httpPatch( endPoint, values ) {
    return this.httpService.patch( endPoint, values );
  }


  RecibeActivo(datos) {
    const data = JSON.stringify(datos);
    return this.httpService.post( "get_Remito", data,);
  }

  httpGet( endPoint, showLoading = true ) {
    return this.httpService.get( endPoint, showLoading );
  }

  httpPostFile( fileName ) {
    return this.httpService.postFile( fileName );
  }

  httpPostFileBase64( file, resolve, reject) {
    return this.httpService.postFileBase64( file, resolve, reject );
  }

  httpPostSimple(url, body, showLoading = true, options?) {
    return this.httpService.postSimple(url, body, showLoading, options);
  }

  // (-) Http

  getMessage(message) {
    let msg = message;
    if(message.message) msg = message.message;
    return msg;
  }

  // (+) Show messages

  showSuccess(message) {
    this.showMessage(message,"toast-success", 'success');
  }
  showError(message) {
    this.showMessage(message,"toast-error", 'danger');
  }
  showWarning(message) {
    this.showMessage(message,"toast-warning", '');
  }
  showMessage(message,cssClass, color){

    // if(message.error && message.error == 404) return;

    let msg = message.message || message;
    let toast = this.toastCtrl.create({
      message: msg,
      cssClass:cssClass,
      duration: 2000,
      position: 'top',
      color: color
    }).then((toastData)=>{
      toastData.present();
    });

  }

  // (-) Show messages

  auth(path): boolean {
    // for (let routeKey in this.global.settings.routes) {
    //   let route = this.global.settings.routes[routeKey];
    //   if(path == ('/' + route.path)) {
    //     if(route.data && route.data.roles) {
    //       const user = this.global.getUser();
    //       if( user ) {
    //         const userRoles = [...user.roles || []];
    //         if(!route.data.roles.some(r=> userRoles.includes(r))) {
    //           return false;
    //         } else {
    //           return true;
    //         }
    //       }
    //     }
    //     return true;
    //   }
    // }
    return true;
  }


  // (+) Image

  showImageUpload(params?: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let actionSheet = await this.actionSheetController.create({
        header: "¿Como desea cargar su imagen?'",
        buttons: [{
          text:   "Galería",
          handler: () => {
            this.showImageUploadTake('gallery', resolve, reject);
          }
        }, {
          text: "Cámara",
          handler: () => {
            this.showImageUploadTake('camera', resolve, reject);
          }
        }, {
          text: "Cancelar",
          role: 'cancel',
          handler: () => {
            resolve(null);
          }
        }]
      });
      await actionSheet.present();
    });
  }

  showImageUploadTake(source, resolve, reject) {
    if (!this.platform.is('cordova')) {
      let element = document.createElement('input');
      element.type = 'file';
      element.accept = 'image/*';
      element.onchange = () => {
        this.httpPostFile(element.files[0])
          .then((result) => {
            resolve(result);
          })
          .catch((error) => {
            reject(error);
          });
      };
      element.click();
    } else {
      let cameraOptions: CameraOptions = {
        quality: 85,
        destinationType: this.camera.DestinationType.DATA_URL,
        encodingType: this.camera.EncodingType.JPEG,
        mediaType: this.camera.MediaType.PICTURE,
        sourceType: source == 'gallery' ? this.camera.PictureSourceType.PHOTOLIBRARY : this.camera.PictureSourceType.CAMERA,
        correctOrientation: true,
        // allowEdit: true
      };
      this.camera.getPicture(cameraOptions).then((file) => {
        this.httpPostFileBase64(file, resolve, reject);
      }, (error) => {
        reject(error);
      });
    }
  }

  // (-) Image


  // (+) Loading
  async showLoading(content = 'Procesando...'){
    // this.loading = await this.loadingController.create({
    //   message: content
    // });
    // await this.loading.present();
    this.global.showLoading();
  }

  async hideLoading(){
    this.global.hideLoading();
    // if(this.loading) await this.loading.dismiss();
  }
  // (-) Loading

  // (+) Map

  loadGoogleMapsLibrary(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (window['google']) {
        return resolve(window['google']);
      }
      let element = document.createElement('script');
      element.id = 'google-maps-api-script';
      element.src = 'https://maps.googleapis.com/maps/api/js?key=' + this.global.settings.keys.googleMaps + '&libraries=places';
      element.type = 'text/javascript';
      element.onload = () => {
        resolve(window['google']);
      };
      element.onerror = (error) => {
        reject();
      }
      document.body.appendChild(element);
    });
  }

  getCurrentLocation() {
    return new Promise( (resolve, reject) => {
      this.geolocation.getCurrentPosition()
        .then((position) => { 
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };
          resolve(pos);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  // (-) Map

  // (+) oneSignal

  oneSignalInitialConfig() {

    console.log('inicio oneSignal ' + this.devicePlatform + '...');
    const appId = this.global.settings.oneSignal.appId;
    
    if (this.devicePlatform != 'browser') {

      const googleProjectNumber = this.devicePlatform == 'android' ? this.global.settings.oneSignal.googleProjectNumber : null;
      console.log(googleProjectNumber)
      OneSignal.initialize(appId);
      OneSignal.Notifications.requestPermission(true).then((accepted: boolean) => {
        console.log("User accepted notifications: " + accepted);
    });
      let myClickListener = async function(event) {
        let notificationData = JSON.stringify(event);
        console.log(notificationData)
      };
      OneSignal.Notifications.addEventListener("click", myClickListener);
  
      const listener = (event: PushSubscriptionChangedState) => {
        console.log("Push subscription changed: " + (event));
    };
    OneSignal.User.pushSubscription.addEventListener("change", listener);
    // Remove the listener
    OneSignal.User.pushSubscription.removeEventListener("change", listener);
      // this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.Notification);
  
      // this.oneSignal.handleNotificationReceived().subscribe((noti) => {
      //   console.log(noti)
      //   this.notificationReceived(noti);
      // });
  
      // this.oneSignal.getIds().then(ids => {
      //   console.log('playerID:', ids);
        
      //   console.log(localStorage.getItem('tokenOS'))
      //   // Guarda el playerID o úsalo según sea necesario
      // });

      // this.oneSignal.handleNotificationOpened().subscribe(async (noti) => {
      //   console.log(noti)
      //   await this.notificationOpen(noti.notification);
      // });
  
      // if (!localStorage.getItem('tokenOS')) {
      //   this.oneSignal.getIds().then( async (info) => {
      //     const d: any = await info;
      //     const val = JSON.stringify(d);
      //     localStorage.setItem('tokenOS', val);
      //     console.log(val, "json")

      //   });
      // }
  
      // setTimeout(() => {
      //   this.oneSignal.endInit();
      // }, 1000);

    }
    else {

      delete window['OneSignal']; // remover oneSignal cargado por plugin para reemplazar por WebPushSDK
      let script: any = document.createElement('script');
      script.type = 'text/javascript';
      script.src = this.global.settings.oneSignal.WebPushSDK;

      script.onload = (res) => {
        console.log('WebPushSDK OK: ' + res)
        window['OneSignal'] = window['OneSignal'] || [];
        let OneSignalBrowser: any = window['OneSignal'];
        OneSignalBrowser.push(function() {
          OneSignalBrowser.init({
            appId
          });
        });
      };

      script.onerror = (error) => {
        console.log('WebPushSDK ERROR: ' + error)
      };

      document.getElementsByTagName('head')[0].appendChild(script);

    }

  }

  // async notificationReceived(noti: OSNotification) {
  //   console.log('RECIBE');
  //   console.log('noti', noti);
  //   console.log('ruta', noti.payload.additionalData.ruta);
  // }

  // async notificationOpen(noti: OSNotification) {

  //   const route = noti.payload.additionalData.ruta;

  //   console.log('ABRE');
  //   console.log('noti', noti);
  //   console.log('ruta', noti.payload.additionalData.ruta);

  //   console.log('NOTIFICACION ABIERTA: ' + route)
  //   // this.gse.publishSomeData({ ruta : noti.payload.additionalData.ruta});

  // }

  // async saveMessages() {
  //   const val = JSON.stringify(this.messages);
  //   localStorage.setItem('messages', val);
  // }

  // async loadMessages() {
  //   this.messages = await JSON.parse(localStorage.getItem('messages')) || [];
  //   return this.messages;
  // }

  // async getMessages() {
  //   await this.loadMessages();
  //   return [...this.messages];
  // }

  add_OnSignal(data) {
    return new Promise( (resolve, reject) => {

      const endPoint = this.global.settings.endPoints.updateDataOnSignal;
      this.httpPost(endPoint, data, false, true).then( (res) => {
        console.log('add_OnSignal OK: ' + JSON.stringify(res))
        resolve(res);
      })
      .catch( (error) => {
        console.log('add_OnSignal ERROR: ' + error);
        reject(error);
      });

    });
  }
  // (-) oneSignal

  // (+) firebase analytics

  firebaseAnalyticsLogEvent(name: string, params: any) {
    console.log('firebaseAnalytics name: ' + name)
    console.log('firebaseAnalytics params: ' + JSON.stringify(params))
    try {
      if (this.devicePlatform == 'browser') {
        this.fireAnalytics.logEvent(name, params).then( (r) => {
          console.log('firebaseAnalytics OK: ' + r)
        });
      }
      else {
        this.firebaseAnalytics.logEvent(name, params).then( (r) => { 
          console.log('firebaseAnalytics OK: ' + r)
        });
      }
    }
    catch(error) {
      console.log('firebaseAnalytics ERROR: ' + error)
    }
  }
  
  // (-) firebase analytics

  //(+) Qr

  setStyleQrCode(){
    let qrcodeContainer: any = document.getElementsByClassName("qrcode")[0];
    let imgQrcodeContainer: any = qrcodeContainer.getElementsByTagName("img")[0];
    qrcodeContainer.style.display = "flex";
    qrcodeContainer.style.justifyContent = "center";
    qrcodeContainer.style.width = "100%";
    qrcodeContainer.style.height = "100%";
    imgQrcodeContainer.style.minWidth = "100%"
  }

  //(-) Qr

  showTimeLapsed(date: Date, fromParamDate: Boolean) {
    let today = moment();
    let paramDate = moment(date);
    let timeLapsed;
    if (fromParamDate) timeLapsed = paramDate.diff(today);
    else timeLapsed = today.diff(paramDate);

    let parts = [];
    const duration = moment.duration(timeLapsed);

    if (!duration || duration.toISOString() === 'P0D') return 'Ha ocurrido un error';

    if (duration.years() >= 1) {
      const years = Math.floor(duration.years());
      parts.push(years + ' ' + (years > 1 ? 'años' : 'año'));
    }

    if (duration.months() >= 1) {
      const months = Math.floor(duration.months());
      parts.push(months + ' ' + (months > 1 ? 'meses' : 'mes'));
    }

    if (duration.days() >= 1) {
      const days = Math.floor(duration.days());
      parts.push(days + ' ' + (days > 1 ? 'dias' : 'dia'));
    }

    if (duration.hours() >= 1) {
      const hours = Math.floor(duration.hours());
      parts.push(hours + ' ' + (hours > 1 ? 'horas' : 'hora'));
    }

    if (duration.minutes() >= 1) {
      const minutes = Math.floor(duration.minutes());
      parts.push(minutes + ' ' + (minutes > 1 ? 'minutos' : 'minuto'));
    }

    if (duration.seconds() >= 1 && parts.length == 0) {
      const seconds = Math.floor(duration.seconds());
      parts.push(seconds + ' ' + (seconds > 1 ? 'segundos' : 'segundo'));
    }

    return parts.join(' ');
  }

  share(id, params?: {message?: string, subject?: string, file?: string | string[], url?: string, other?: any}) {
    var node = document.querySelector(id);
    if (params && params.other && params.other.isHidden) node.style.display = 'flex';
    domtoimage.toPng(node)
    .then((dataUrl) => {

        if (params && params.other && params.other.isHidden) node.style.display = 'none';

        if (this.platform.is('cordova')) {
          this.socialSharing
            .share(params.message, params.subject, dataUrl)
            .then(res => {
            })
            .catch(err => {
              console.log('error share', err);
              this.showError(err);
            });
        } else {
          this.downloadBase64File(dataUrl, params.subject + '.png');
        }

      })
      .catch((error) => {
        if (params && params.other && params.other.isHidden) node.style.display = 'none';
        console.error('oops, something went wrong!', error);
        this.showError(error);
      });
  }

  
  downloadBase64File(base64Data, name) {
    const linkSource = base64Data;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = name;
    downloadLink.click();
  }

  //(+) Social login

  async  loginSocial(loginType) {
    return new Promise( (resolve, reject) => {
      let user: any = { loginType };
      if (loginType == this.global.settings.loginSocial.types.google) {
   //     console.log('global', this.global.settings.loginSocial.types.google)
        let params: any = {};
        if (this.devicePlatform != 'ios') {
          params = {
            webClientId: this.global.settings.firebase.webClientId,
            offline: false
          };
        }
        this.google.login(params).then( async (res) => {
     //     console.log('Good ' + loginType + ' login: ', res);
          
          this.firebaseAnalyticsLogEvent('login', {estado: 'OK', tipo: 'G+'});

          const idToken = res.idToken;
          const accessToken  = res.accessToken;
          const credential = accessToken ? firebase.auth.GoogleAuthProvider.credential(idToken, accessToken) : firebase.auth.GoogleAuthProvider.credential(idToken);
          user.password = accessToken;
          user.userId = res.userId;
          user.Token = this.getToken(0, accessToken);
          user.firstName = res.givenName;
          user.lastName = res.familyName;
          user.emailAddress = res.email;
          user.TipoLogin = 'G';

          if (!user.emailAddress) {
            this.logoutSocial(loginType);
            reject('No se pudo obtener su email');
            return;
          }

          this.fireAuth.signInWithCredential(credential).then( (res) => {
            console.log('Good firebase' + loginType + ' login: ', res);
            resolve(user);
          })
          .catch( (error) => {
            console.log('Bad firebase' + loginType + ' login: ', error);
            reject(error);
          });
        
        })
        .catch( (error) => {
            console.log('Bad ' + loginType + ' login: ', error);
            reject(error);
        });
      }
      else if (loginType == this.global.settings.loginSocial.types.apple) {
        if (this.devicePlatform != 'ios') {
          reject('Login con apple disponible en app IOS');
          return;
        }

        const requestedScopes = [ASAuthorizationAppleIDRequest.ASAuthorizationScopeFullName, ASAuthorizationAppleIDRequest.ASAuthorizationScopeEmail];
        this.apple.signin({requestedScopes}).then( async (res: AppleSignInResponse) => {
          console.log('Good ' + loginType + ' login: ', res);

          const provider = new firebase.auth.OAuthProvider('apple.com');
          const credential = provider.credential({
            idToken: res.identityToken
          });

          // apple solo devuelve nombre y email
          user.password = res.identityToken;
          user.userId = res.user;
          user.Token = res.identityToken;
          user.firstName = res.fullName.givenName;
          user.lastName = res.fullName.familyName;
          user.emailAddress = res.email;
          user.TipoLogin = 'I';

          if (!user.emailAddress) {
            this.logoutSocial(loginType);
            reject('No se pudo obtener su email');
            return;
          }

          this.fireAuth.signInWithCredential(credential).then( (res) => {
            console.log('Good firebase' + loginType + ' login: ', res);
            resolve(user);
          })
          .catch( (error) => {
            console.log('Bad firebase' + loginType + ' login: ', error);
            reject(error);
          });

        })
        .catch( (error) => {
          console.log('Bad ' + loginType + ' login: ', error);
          reject(error);
        });
      }
      else reject('Tipo de login no soportado');

    });
  }

  logoutSocial(loginType?) {
    return new Promise( async (resolve, reject) => {

      await this.google.trySilentLogin({offline: false}).then( async (res) => {
        await this.google.logout().then( (res) => {
        
        })
        .catch( (error) => {
          console.log('google logout ERROR: ' + error)
          this.showError(error);
        })
      })
      .catch( (error) => {
        console.log('google trySilentLogin ERROR: ' + error)
        this.showError(error);
      });

      this.fireAuth.signOut().then( () => {
        console.log('Good ' + loginType + ' logout');
        resolve('Logout social made');
      })
      .catch( (error) => {
        console.log('Bad ' + loginType + ' logout: ', error);
        this.showError(error);
        reject('Logout social error');
      });

    });
  }

  //(-) Social login

  logout() {
    //Si tiene algún tipo de login social, llama a la función logoutSocial
    let loginType = this.global.pop(this.global.settings.storage.loginType);
    if(loginType)  this.logoutSocial();

    this.global.removeUser();
    this.removeStorage();
    this.global.removeTotalQuantityProducts();
  }

  removeStorage() {
    this.global.remove(this.global.settings.storage.addressInfo);
    this.global.remove(this.global.settings.storage.address);
    this.global.remove(this.global.settings.storage.addressLatitude);
    this.global.remove(this.global.settings.storage.addressLongitude);
    this.global.remove(this.global.settings.storage.deliveryDate);
    this.global.remove(this.global.settings.storage.deliveryDateFormated);    
    this.global.remove(this.global.settings.storage.deliverySchedule);
    this.global.remove(this.global.settings.storage.deliveryScheduleRange);    
    this.global.remove(this.global.settings.storage.floor);
    this.global.remove(this.global.settings.storage.idDistribCenter);
    this.global.remove(this.global.settings.storage.neighborhood);
    this.global.remove(this.global.settings.storage.orderId);    
    this.global.remove(this.global.settings.storage.phoneNumber);
    this.global.remove(this.global.settings.storage.saveAddress);
    this.global.remove(this.global.settings.storage.userName);    
  }

  // (+) Mobbex

  getStorageCheckout(idPedido, total) {
    const checkout = this.global.load(this.global.settings.storage.checkout);
    if (checkout) {
      const today = moment();
      const diff = moment(checkout.expireDate).diff(today);
      if (diff >= 0) {
        if (checkout.idPedido == idPedido && checkout.total == total) return checkout;
        else {
          this.clearCardStorage();
          return false;
        }
      }
      else {
        this.clearCardStorage();
        return false;
      }
    }

    return false;
  }

  clearCardStorage() {
    this.global.remove(this.global.settings.storage.tempNewCard);
    this.global.remove(this.global.settings.storage.checkout);
    this.global.remove(this.global.settings.storage.payment);
  }

  getCheckout(orderHeader: any) { //OrderHeader contiene la info del pedido actual
    return new Promise( (resolve, reject) => {
      let checkout = this.getStorageCheckout(orderHeader.IdPedido, orderHeader.total);
      console.log(checkout, "stores")
      if (checkout) {
        resolve({
          result: true,
          checkout
        });
      }
      else {

     //   const user = this.global.getUser();

      //  console.log('idpedido', orderHeader.IdPedido)
        this.httpService.postCheckout(orderHeader.IdPedido).subscribe((resp: any) => {
            let res = JSON.parse(resp.data);
            console.log("resss",res.data);
            console.log("res page service",res);
            if (res.result === true) {
              console.log(res.url)
            //  console.log(JSON.stringify(res.data.intent, null, 2))
              checkout = {
                url: res.data.url,
                // intentToken: res.data.intent.token,
                wallet: res.data.wallet,
                expireDate: moment().add(59, 'minutes'),
                total: orderHeader.total,
                idPedido: orderHeader.IdPedido
              }
              resolve({
                result: true,
                checkout
              });
            }
            else resolve({
              result: false,
              error: 'Error al generar pago con tarjeta'
            });
          })

      //   const url = this.global.settings.mobbex.createCheckoutUrl;
      //   const body = {
      //     total: orderHeader.total,
      //     description: 'Compra de productos',
      //     reference: orderHeader.IdPedido,
      //     currency: 'ARS',
      //     test: environment.mobbexTest,
      //     customer: {
      //       phone: user.Telefono,
      //       email: user.Mail,
      //       name: user.Nombre + ' ' + user.Apellido,
      //       identification: user.DNI || '12123123',
      //       uid: user.Id
      //     },
      //     wallet: true,
      //     options: {
      //       domain: window.location.origin
      //     }
      //   };
      //   console.log('BODYDatosClienteTarjeta: + ' + JSON.stringify(body, null, 2))
      //   const options = {
      //     headers: {
      //       'Content-Type':  'application/json',
      //       'x-api-key': this.global.settings.mobbex.apiKey,
      //       'x-access-token': this.global.settings.mobbex.accessToken
      //     }
      //   };

      //   this.httpPostSimple(url, body, false, options).then( (res) => {
      //     if (res.result === true) {
      //       console.log(JSON.stringify(res.data, null, 2))
      //       checkout = {
      //         intentToken: res.data.intent.token,
      //         wallet: res.data.wallet,
      //         expireDate: moment().add(59, 'minutes'),
      //         total: orderHeader.total,
      //         idPedido: orderHeader.IdPedido
      //       }
      //       resolve({
      //         result: true,
      //         checkout
      //       });
      //     }
      //     else resolve({
      //       result: false,
      //       error: 'Error al generar pago con tarjeta'
      //     });

      //   })
      //   .catch( (error) => {
      //     resolve({
      //       result: false,
      //       error
      //     });
      //   });

       }

    });

  }

  // (-) Mobbex

  getToken(id: any, n: any) {
    const today = new Date();
    const hora = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
    const dataToken = id + hora;

    const getMD5 = Md5.hashStr(dataToken) + '';

    if (n === undefined) {
      n = '0000';
    }
    return getMD5.toUpperCase() + '#' + n;
  }

  getOfuscar(c) {

    const Of = [];

    const p = Math.round(Math.random() * (64 - 0) + 0);
    const p1 = Math.round(Math.random() * (64 - 0) + 0);
    const k = c.split('');
    const k0 = this.getPatron(p).split('');
    const k1 = this.getPatron(p1).split('');
    let nK1 = '';
    for (let i = 0; i < k.length; i++) {
      const g = k[i];
      let n = 0;
      for (let a = 0; a < k0.length; a++) {
        if (g === k0[a]) {
          n = a;
          a = 1000;
        }
      }
      nK1 = nK1 + k1[n];
    }

    let cp = p + '';
    if (cp.length < 2) {
      cp = '0' + p;
    }

    let cp1 = p1 + '';
    if (cp1.length < 2) {
      cp1 = '0' + p1;
    }

    Of.push(nK1);
    Of.push(cp + cp1);

    return Of;
  }

  getPatron(n: number) {

    let pat;
    switch (n) {
      case 0:
        pat = this.global.settings.k.k00;
        break;
      case 1:
        pat = this.global.settings.k.k01;
        break;
      case 2:
        pat = this.global.settings.k.k02;
        break;
      case 3:
        pat = this.global.settings.k.k03;
        break;
      case 4:
        pat = this.global.settings.k.k04;
        break;
      case 5:
        pat = this.global.settings.k.k05;
        break;
      case 6:
        pat = this.global.settings.k.k06;
        break;
      case 7:
        pat = this.global.settings.k.k07;
        break;
      case 8:
        pat = this.global.settings.k.k08;
        break;
      case 9:
        pat = this.global.settings.k.k09;
        break;
      case 10:
        pat = this.global.settings.k.k10;
        break;
      case 11:
        pat = this.global.settings.k.k11;
        break;
      case 12:
        pat = this.global.settings.k.k12;
        break;
      case 13:
        pat = this.global.settings.k.k13;
        break;
      case 14:
        pat = this.global.settings.k.k14;
        break;
      case 15:
        pat = this.global.settings.k.k15;
        break;
      case 16:
        pat = this.global.settings.k.k16;
        break;
      case 17:
        pat = this.global.settings.k.k17;
        break;
      case 18:
        pat = this.global.settings.k.k18;
        break;
      case 19:
        pat = this.global.settings.k.k19;
        break;
      case 20:
        pat = this.global.settings.k.k20;
        break;
      case 21:
        pat = this.global.settings.k.k21;
        break;
      case 22:
        pat = this.global.settings.k.k22;
        break;
      case 23:
        pat = this.global.settings.k.k23;
        break;
      case 24:
        pat = this.global.settings.k.k24;
        break;
      case 25:
        pat = this.global.settings.k.k25;
        break;
      case 26:
        pat = this.global.settings.k.k26;
        break;
      case 27:
        pat = this.global.settings.k.k27;
        break;
      case 28:
        pat = this.global.settings.k.k28;
        break;
      case 29:
        pat = this.global.settings.k.k29;
        break;
      case 30:
        pat = this.global.settings.k.k30;
        break;
      case 31:
        pat = this.global.settings.k.k31;
        break;
      case 32:
        pat = this.global.settings.k.k32;
        break;
      case 33:
        pat = this.global.settings.k.k33;
        break;
      case 34:
        pat = this.global.settings.k.k34;
        break;
      case 35:
        pat = this.global.settings.k.k35;
        break;
      case 36:
        pat = this.global.settings.k.k36;
        break;
      case 37:
        pat = this.global.settings.k.k37;
        break;
      case 38:
        pat = this.global.settings.k.k38;
        break;
      case 39:
        pat = this.global.settings.k.k39;
        break;
      case 40:
        pat = this.global.settings.k.k40;
        break;
      case 41:
        pat = this.global.settings.k.k41;
        break;
      case 42:
        pat = this.global.settings.k.k42;
        break;
      case 43:
        pat = this.global.settings.k.k43;
        break;
      case 44:
        pat = this.global.settings.k.k44;
        break;
      case 45:
        pat = this.global.settings.k.k45;
        break;
      case 46:
        pat = this.global.settings.k.k46;
        break;
      case 47:
        pat = this.global.settings.k.k47;
        break;
      case 48:
        pat = this.global.settings.k.k48;
        break;
      case 49:
        pat = this.global.settings.k.k49;
        break;
      case 50:
        pat = this.global.settings.k.k50;
        break;
      case 51:
        pat = this.global.settings.k.k51;
        break;
      case 52:
        pat = this.global.settings.k.k52;
        break;
      case 53:
        pat = this.global.settings.k.k53;
        break;
      case 54:
        pat = this.global.settings.k.k54;
        break;
      case 55:
        pat = this.global.settings.k.k55;
        break;
      case 56:
        pat = this.global.settings.k.k56;
        break;
      case 57:
        pat = this.global.settings.k.k57;
        break;
      case 58:
        pat = this.global.settings.k.k58;
        break;
      case 59:
        pat = this.global.settings.k.k59;
        break;
      case 60:
        pat = this.global.settings.k.k60;
        break;
      case 61:
        pat = this.global.settings.k.k61;
        break;
      case 62:
        pat = this.global.settings.k.k62;
        break;
      case 63:
        pat = this.global.settings.k.k63;
        break;
      case 64:
        pat = this.global.settings.k.k64;
        break;
    }

    return pat;
  }

//MERCADO PAGO

createPreference(preference: any) {
  return this.http.post('https://api.mercadopago.com/checkout/preferences?access_token=' + this.accessToken, preference);
}

//productos datas json



validateConvenioSv(CodConvenio) {
  let body ={
    "CodConvenio": CodConvenio
  }
  return this.http.post(environment.serverUrl + '/ExisteConvenio', body,{headers: { 'Content-Type' : 'application/json'}
  
  })
  
  }
}



